// This file is for the Map webcomponent
// It is used in the report to display each map
import { esri, olarcgis} from '../sccesri.js';
import * as olControl from 'ol/control';
import { WMSCapabilities, WMTSCapabilities, GeoJSON } from 'ol/format';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import {applyStyle} from 'ol-mapbox-style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import VectorTileLayer from 'ol/layer/VectorTile.js';
import VectorTileSource from 'ol/source/VectorTile.js';
import TileArcGISRest from 'ol/source/TileArcGISRest';
import TileGrid from 'ol/tilegrid/TileGrid.js';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';
import Map from 'ol/Map';
import {transform} from 'ol/proj';
import { Fill, Style, Stroke } from 'ol/style';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import { displayWarningReportContainsOmissions, removeLayerFromWarningReportContainsOmissions } from '../report-shared.js';

export class ReportMap extends HTMLElement {

    constructor() {
        super();
        this.mapLegendLabels = {};
        this.legendResults = {};
        this.legendRequested = {};
        this.legendProcessingBacklog = {};
    }

    connectedCallback() {
        //this.innerHTML = "<span>placeholder</span>";
    }

    async setup(siteGeoJSON, mapElementName, mapConfig, isDrillDownMap) {
        const thisComponent = this;

        thisComponent.mapEle = document.createElement("div");
        thisComponent.mapEle.id = mapElementName;
        thisComponent.mapEle.classList = 'map';
        thisComponent.progress = new Progress(mapElementName + 'Progress');
        thisComponent.legendEle = document.createElement("div");
        thisComponent.legendEle.id = mapElementName + 'Legend';
        thisComponent.legendEle.classList = 'ol_legend';

        // const shadowRoot = this.attachShadow({mode: 'open'});
        // shadowRoot.appendChild(this.mapEle);
        // shadowRoot.appendChild(this.progress.el);
        // shadowRoot.appendChild(this.legendEle);

        thisComponent.appendChild(this.mapEle);
        thisComponent.appendChild(this.progress.el);
        thisComponent.appendChild(this.legendEle);

        let projection = 'EPSG:3857';
        if (isDrillDownMap && mapConfig.map.projection) {
            projection = mapConfig.map.projection;
        } else if (mapConfig.projection) {
            projection = mapConfig.projection;
        }
        let mapElement = new Map({
            target: mapElementName,
            view: new View({
                center: transform([153, -26.7], 'EPSG:4326', projection),
                zoom: 13,
                maxZoom:20,
                projection: projection
            }),
            controls: [new olControl.FullScreen(), new olControl.ScaleLine(), new olControl.Zoom()]
        });
        mapElement.on('moveend', () => {
            thisComponent.progress.reset();
        });

        if (isDrillDownMap) {
            for (let layerIndex = mapConfig.map.basemapLayers.length - 1; layerIndex >= 0; layerIndex--) {
                const elementLayer = mapConfig.map.basemapLayers[layerIndex];
                await this.addLayerToMap(elementLayer, mapElement, thisComponent.progress, mapConfig.map.basemapLayers.length, layerIndex, isDrillDownMap);
            }
            if (mapConfig.map.overlayLayers) {
                for (let layerIndex = mapConfig.map.overlayLayers.length - 1; layerIndex >= 0; layerIndex--) {
                    const overlayLayer = mapConfig.map.overlayLayers[layerIndex];
                    await this.addLayerToMap(overlayLayer, mapElement, thisComponent.progress, mapConfig.map.basemapLayers.length + mapConfig.map.overlayLayers.length, 0, isDrillDownMap);
                    if (!mapConfig.map.showLegend && overlayLayer.showLegend) {
                        this.renderLegend(mapElementName, overlayLayer);
                    }
                }
            }
            if (mapConfig.map.overlayLayer && mapConfig.map.overlayLayer.service) {
                const overlayLayer = mapConfig.map.overlayLayer;
                await this.addLayerToMap(overlayLayer, mapElement, thisComponent.progress, mapConfig.map.basemapLayers.length + 1, 0, isDrillDownMap);
                if (!mapConfig.map.showLegend && overlayLayer.showLegend) {
                    this.renderLegend(mapElementName, overlayLayer);
                }
            }
            if (mapConfig.map.showLegend) {
                // the Legend from a MapService layer is better than that provided by WMS
                // so use it if there is one
                if (mapConfig.map.overlayLayers 
                    && mapConfig.map.overlayLayers.length > 0
                    && mapConfig.map.overlayLayers[0].service == 'MapService') {
                    let extraLayerIds = mapConfig.map.overlayLayers.slice(1).map(l => l.layerId);
                    if (mapConfig.map.overlayLayer
                        && mapConfig.map.overlayLayer.layerId) {
                        extraLayerIds.push(mapConfig.map.overlayLayer.layerId);
                    }
                    this.renderLegend(mapElementName, mapConfig.map.overlayLayers[0], extraLayerIds);
                } else if (mapConfig.map.overlayLayer 
                    && mapConfig.map.overlayLayer.service == 'MapService') {
                    this.renderLegend(mapElementName, mapConfig.map.overlayLayer);
                } else {
                    this.renderLegend(mapElementName, mapConfig);
                }
            }
        } else {
            for (let layerIndex = mapConfig.layers.length - 1; layerIndex >= 0; layerIndex--) {
                let elementLayer = mapConfig.layers[layerIndex];
                await this.addLayerToMap(elementLayer, mapElement, thisComponent.progress, mapConfig.layers.length, layerIndex, false);
                if (elementLayer.showLegend) {
                    this.renderLegend(mapElementName, elementLayer);
                }
            }
        }

        //Add the results of the site location to the map.
        let geojsonFormat = new GeoJSON();
        let vectorSource = new VectorSource();
        let mapElementLayer = new VectorLayer();

        if (isDrillDownMap) {
            mapElementLayer.setStyle(new Style({
                fill: new Fill({ color: this.HEXtoRGBaArray(mapConfig.map.siteLayerStyle.color, mapConfig.map.siteLayerStyle.fillOpacity) }),
                stroke: new Stroke({
                    color: this.HEXtoRGBaArray(mapConfig.map.siteLayerStyle.color, mapConfig.map.siteLayerStyle.opacity),
                    width: mapConfig.map.siteLayerStyle.weight
                })
            }));
        } else {
            mapElementLayer.setStyle(new Style({
                fill: new Fill({ color: this.HEXtoRGBaArray(mapConfig.layerStyle.color, mapConfig.layerStyle.fillOpacity) }),
                stroke: new Stroke({
                    color: this.HEXtoRGBaArray(mapConfig.layerStyle.color, mapConfig.layerStyle.opacity),
                    width: mapConfig.layerStyle.weight
                })
            }));
        }
        let mapCollection = mapElement.getLayers();
        mapCollection.insertAt(0, mapElementLayer);
        mapElementLayer.setZIndex(999);

        vectorSource.addFeatures(geojsonFormat.readFeatures(siteGeoJSON, { dataProjection: 'EPSG:4326', featureProjection: projection }));
        mapElementLayer.setSource(vectorSource);

        if (mapElementLayer.getSource()) {
            let mapElementLayerBounds = mapElementLayer.getSource().getExtent();
            (mapElement.getView()).fit(mapElementLayerBounds, mapElement.getSize());
            let mapView = (mapElement.getView());
            let mapZoom = mapView.getZoom();
            mapView.setZoom(mapZoom - 1);
            mapElement.setView(mapView);
        }

        if (isDrillDownMap) {
            if (mapConfig.map.minZoomLevel) {
                if (mapElement.getView().getZoom() > mapConfig.map.minZoomLevel) {
                    mapElement.getView().setZoom(mapConfig.map.minZoomLevel)
                }
            }
        }
    }

    async addLayerToMap(elementLayer, mapElement, progress, numberOfLayers, layerIndex, isDrilldownOverlay) {
        switch (elementLayer.service) {
            case "WMS": {
                let layerPlaceholder;
                if (elementLayer.matrixSet) {
                    layerPlaceholder = new TileLayer({
                        source: new TileWMS({
                            attributions: [new olControl.Attribution({
                                html: elementLayer.attribution || ''
                            })],
                            params: {
                                'tiled': true,
                                'format': 'image/png',
                                'LAYERS': elementLayer.layerId,
                                'STYLES': elementLayer.style
                            },
                            url: elementLayer.url
                        })
                    });
                    layerPlaceholder.getSource().on('tileloadstart', (d) => {
                        progress.addLoading(elementLayer.url + '#tile' + d.tile.ol_uid);
                    });
                    layerPlaceholder.getSource().on('tileloadend', (d) => {
                        progress.addLoaded(elementLayer.url + '#tile' + d.tile.ol_uid);
                        removeLayerFromWarningReportContainsOmissions(elementLayer.layerName);
                    });
                    layerPlaceholder.getSource().on('tileloaderror', (d) => {
                        progress.addError(elementLayer.url + '#tile' + d.tile.ol_uid);
                        displayWarningReportContainsOmissions(elementLayer.layerName);
                    });
                } else {
                    layerPlaceholder = new ImageLayer({
                        source: new ImageWMS({
                            attributions: [new olControl.Attribution({
                                html: elementLayer.attribution || ''
                            })],
                            params: { "tiled": "true", 'LAYERS': elementLayer.layerId, 'STYLES': elementLayer.style },
                            url: elementLayer.url
                        })
                    });
                    layerPlaceholder.getSource().on('imageloadstart', () => {
                        progress.addLoading(elementLayer.url);
                    });
                    layerPlaceholder.getSource().on('imageloadend', () => {
                        progress.addLoaded(elementLayer.url);
                        removeLayerFromWarningReportContainsOmissions(elementLayer.layerName);
                    });
                    layerPlaceholder.getSource().on('imageloaderror', () => {
                        progress.addError(elementLayer.url);
                        displayWarningReportContainsOmissions(elementLayer.layerName);
                    });
                }
                layerPlaceholder.setOpacity(elementLayer.opacity || 1);
                layerPlaceholder.setZIndex(isDrilldownOverlay ? 998 : (100 - layerIndex))
                mapElement.addLayer(layerPlaceholder);
                break;
            }
            case "WMTS": {
                let parser = new WMTSCapabilities();
                try {
                    let capabilities = (elementLayer, mapElement, layerIndex) => {
                        $.get(elementLayer.url + '?REQUEST=getcapabilities', {},
                            (response) => {
                                let result = parser.read(response);
                                let options = optionsFromCapabilities(result,
                                    { layer: elementLayer.layerId, matrixSet: elementLayer.matrixSet });
                                if (options != null) {
                                    options.crossOrigin = 'anonymous';
                                    options.style = elementLayer.style || options.style;
                                    let layerPlaceholder = new TileLayer({
                                        source: new WMTS(/** @type {!olx.source.WMTSOptions} */(options))
                                    })
                                    if (layerPlaceholder != null) {
                                        layerPlaceholder.getSource().on('tileloadstart', (d) => {
                                            progress.addLoading(elementLayer.url + '#tile' + d.tile.ol_uid);
                                        });
                                        layerPlaceholder.getSource().on('tileloadend', (d) => {
                                            progress.addLoaded(elementLayer.url + '#tile' + d.tile.ol_uid);
                                            removeLayerFromWarningReportContainsOmissions(elementLayer.layerName);
                                        });
                                        layerPlaceholder.getSource().on('tileloaderror', (d) => {
                                            progress.addError(elementLayer.url + '#tile' + d.tile.ol_uid);
                                            displayWarningReportContainsOmissions(elementLayer.layerName);
                                        });

                                        layerPlaceholder.setOpacity(elementLayer.opacity || 1);

                                        //Insert the layer at the correct level on the map
                                        let mapCollection = mapElement.getLayers();

                                        if (layerIndex > mapCollection.getLength()) {
                                            mapCollection.push(layerPlaceholder);
                                        } else {
                                            mapCollection.insertAt(layerIndex, layerPlaceholder);
                                        }
                                    }
                                }
                            }, 'xml');
                    }
                    capabilities(elementLayer, mapElement, numberOfLayers - layerIndex);
                }
                catch (err) {
                    let capabilities = (elementLayer, mapElement, layerIndex) => {
                        $.get(elementLayer.url + '1.0.0/WMTSCapabilities.xml', {},
                            (response) => {
                                let result = parser.read(response)
                                let options = optionsFromCapabilities(result,
                                    { layer: elementLayer.layerId, matrixSet: elementLayer.matrixSet });
                                if (options != null) {
                                    options.crossOrigin = 'anonymous';
                                    options.style = elementLayer.style;
                                    let layerPlaceholder = new TileLayer({
                                        source: new WMTS(/** @type {!olx.source.WMTSOptions} */(options))
                                    })
                                    if (layerPlaceholder != null) {
                                        layerPlaceholder.setOpacity(elementLayer.opacity || 1);
                                        layerPlaceholder.getSource().on('tileloadstart', (d) => {
                                            progress.addLoading(elementLayer.url + '#tile' + d.tile.ol_uid);
                                        });
                                        layerPlaceholder.getSource().on('tileloadend', (d) => {
                                            progress.addLoaded(elementLayer.url + '#tile' + d.tile.ol_uid);
                                            removeLayerFromWarningReportContainsOmissions(elementLayer.layerName);
                                        });
                                        layerPlaceholder.getSource().on('tileloaderror', (d) => {
                                            progress.addError(elementLayer.url + '#tile' + d.tile.ol_uid);
                                            displayWarningReportContainsOmissions(elementLayer.layerName);
                                        });
                                        //Insert the layer at the correct level on hte map
                                        let mapCollection = mapElement.getLayers();
                                        mapCollection.insertAt(layerIndex, layerPlaceholder);
                                    }
                                }
                            }, 'xml');
                    }
                    capabilities(elementLayer, mapElement, numberOfLayers - layerIndex)
                }
                break;
            }
            case "TileLayer": {
                let layerPlaceholder = new TileLayer({
                    source: new XYZ({
                        crossOrigin: 'anonymous',
                        attributions: [new olControl.Attribution({
                            html: elementLayer.attribution
                        })],
                        url: elementLayer.url
                    })
                });
                layerPlaceholder.setOpacity(elementLayer.opacity || 1);
                layerPlaceholder.getSource().on('tileloadstart', (d) => {
                    progress.addLoading(elementLayer.url + '#tile' + d.tile.ol_uid);
                });
                layerPlaceholder.getSource().on('tileloadend', (d) => {
                    progress.addLoaded(elementLayer.url + '#tile' + d.tile.ol_uid);
                    removeLayerFromWarningReportContainsOmissions(elementLayer.layerName);
                });
                layerPlaceholder.getSource().on('tileloaderror', (d) => {
                    progress.addError(elementLayer.url + '#tile' + d.tile.ol_uid);
                    displayWarningReportContainsOmissions(elementLayer.layerName);
                });

                //mapElement.addLayer(layerPlaceholder);
                layerPlaceholder.setZIndex(isDrilldownOverlay ? 998 : (100 - layerIndex + 1))
                mapElement.addLayer(layerPlaceholder);
                break;
            }
            case "MapService": {                
                let url = elementLayer.url;
                let params = { layers: "show:" + elementLayer.layerId, opacity: elementLayer.opacity };
                // If a layer is secured, send the token with it
                if (url.indexOf('_Secure_') > -1) {
                    const cookieAccessToken = this.getCookie('gis_access_token');
                    if (cookieAccessToken) {
                        params.token = cookieAccessToken;
                    }
                }
                if (elementLayer.dynamicLayers) {
                    params.dynamicLayers = JSON.stringify(elementLayer.dynamicLayers);
                }
                let layerPlaceholder = new TileLayer({
                    source: new TileArcGISRest({
                        attributions: [new olControl.Attribution({
                            html: elementLayer.attribution || ''
                        })],
                        params: params,
                        url: url
                    })
                });
                layerPlaceholder.setOpacity(elementLayer.opacity || 1);
                layerPlaceholder.getSource().on('tileloadstart', (d) => {
                    progress.addLoading(elementLayer.url + '#tile' + d.tile.ol_uid);
                });
                layerPlaceholder.getSource().on('tileloadend', (d) => {
                    progress.addLoaded(elementLayer.url + '#tile' + d.tile.ol_uid);
                    removeLayerFromWarningReportContainsOmissions(elementLayer.layerName);
                });
                layerPlaceholder.getSource().on('tileloaderror', (d) => {
                    progress.addError(elementLayer.url + '#tile' + d.tile.ol_uid, elementLayer.layerName);
                    displayWarningReportContainsOmissions(elementLayer.layerName);
                });
                layerPlaceholder.setZIndex(isDrilldownOverlay ? 998 : (100 - layerIndex));
                mapElement.addLayer(layerPlaceholder);
                break;
            }
            case "FeatureService": {
                let styleOverride = elementLayer.style.override ? elementLayer.style : null;
                let layerPlaceholder = esri.FeatureService.layer(elementLayer.url, elementLayer.layerId, elementLayer.layerName, styleOverride);
                layerPlaceholder.setOpacity(elementLayer.opacity || 1);
                //Insert the layer at the correct level on the map
                let mapCollection = mapElement.getLayers();
                layerPlaceholder.setZIndex(isDrilldownOverlay ? 998 : (100 - layerIndex));
                if (layerIndex > mapCollection.getLength()) {
                    mapCollection.push(layerPlaceholder);
                } else {
                    mapCollection.insertAt(layerIndex, layerPlaceholder);
                }
                
                break;
            }
            case "VectorTileService": {
                const vectorTileServiceUrl = elementLayer.url + (elementLayer.url.endsWith('/') ? '' : '/');
                const serviceMetadata = await fetch(vectorTileServiceUrl).then(response => response.json());
                const projection = 'EPSG:' + serviceMetadata.tileInfo.spatialReference.wkid;
                const extent = [ serviceMetadata.fullExtent.xmin, serviceMetadata.fullExtent.ymin, serviceMetadata.fullExtent.xmax, serviceMetadata.fullExtent.ymax ];
                const origin = [ serviceMetadata.tileInfo.origin.x, serviceMetadata.tileInfo.origin.y ];
                const resolutions = serviceMetadata.tileInfo.lods.map(l => l.resolution);
                const tileSize = serviceMetadata.tileInfo.rows;
                const tiles = serviceMetadata.tiles[0];
                const tileGrid = new TileGrid({
                    extent,
                    origin,
                    resolutions,
                    tileSize
                });

                const layer = new VectorTileLayer({
                    source: new VectorTileSource({
                        attributions: [new olControl.Attribution({
                            html: elementLayer.attribution || ''
                        })],
                        url: vectorTileServiceUrl + tiles + (tiles.endsWith('/') ? '' : '/'),
                        projection: projection,
                        tileGrid: tileGrid
                    })
                });
                let styleUrl = vectorTileServiceUrl + serviceMetadata.defaultStyles + (serviceMetadata.defaultStyles.endsWith('/') ? '' : '/');
                if (elementLayer.style && (elementLayer.style.startsWith('http') || elementLayer.style.startsWith('vector_tile_styles'))) {
                    styleUrl = elementLayer.style;
                }
                await applyStyle(layer, 
                    styleUrl,
                    { resolutions: tileGrid.getResolutions() });

                mapElement.addLayer(layer);
                break;
            }
            default: {
                $('#warning').html('<strong>Warning:</strong> The layer of type ' + elementLayer.service + ' is not currently supported, please correct this.');
                $('#warning').show();
            }
        }
    }

    getHTMLCleanString(dirtyString) {
        let cleanString = dirtyString.replace(/([^a-z0-9]+)/gi, "");
        return cleanString;
    }

    renderLegend(mapElementName, elementLayer, extraLayerIds) {

        if (!this.mapLegendLabels[mapElementName + 'Legend']) {
            this.mapLegendLabels[mapElementName + 'Legend'] = [];
        }

        switch (elementLayer.service) {
            case "WMTS":
            case "VectorTileService":
                break
            case "WMS": {
                //Get the layer name so that it can be used in the legend.
                new WMSCapabilities();
                let wmsVersion = "1.3.0"
                if (this.options && this.options.wmsVersion) {
                    wmsVersion = this.options.wmsVersion;
                }
                $.ajax({
                    url: elementLayer.url + '?version=' + wmsVersion + '&service=WMS&request=GetCapabilities',
                    type: "GET",
                    dataType: "xml",
                    controlObject: this,
                    success: (response) => {
                        this.generateWMSLegend(response, mapElementName + 'Legend', elementLayer)
                    }
                });
                break;
            }
            case "FeatureService": {
                let url = elementLayer.url + elementLayer.layerId;
                let options = { f: 'pjson' };

                if (this.legendResults[url]) {
                    this.generateESRILegend(this.legendResults[url], mapElementName + 'Legend', elementLayer, extraLayerIds);
                } else {

                    if (!this.legendProcessingBacklog[url]) {
                        this.legendProcessingBacklog[url] = []
                    }
                    this.legendProcessingBacklog[url].push({ 'element': mapElementName + 'Legend', 'elementLayer': elementLayer, 'extraLayerIds': extraLayerIds });

                    if (!this.legendRequested[url]) {

                        this.legendRequested[url] = true;

                        let legendRequest = $.ajax({
                            type: "GET",
                            url: url,
                            data: options,
                            dataType: "jsonp"
                        });
                        legendRequest.done((data) => {
                            this.legendResults[url] = data
                            for (let backlogID = 0; backlogID < this.legendProcessingBacklog[url].length; backlogID++) {
                                this.generateESRILegend(this.legendResults[url], this.legendProcessingBacklog[url][backlogID].element, 
                                    this.legendProcessingBacklog[url][backlogID].elementLayer,
                                    this.legendProcessingBacklog[url][backlogID].extraLayerIds);
                            }
                        });
                    }
                }
                break;
            }
            case "MapService": {
                let url = elementLayer.url + (elementLayer.url.endsWith("/") ? "" : "/") + 'legend';
                let params = { f: 'pjson' };

                if (url.indexOf('_Secure_') > -1) {
                    const cookieAccessToken = this.getCookie('gis_access_token');
                    if (cookieAccessToken) {
                        params.token = cookieAccessToken;
                    }
                }
                if (elementLayer.dynamicLayers) {
                    params.dynamicLayers = JSON.stringify(elementLayer.dynamicLayers);
                }
                if (this.legendResults[url]) {
                    this.generateESRILegend(this.legendResults[url], mapElementName + 'Legend', elementLayer, extraLayerIds);
                } else {

                    if (!this.legendProcessingBacklog[url]) {
                        this.legendProcessingBacklog[url] = [];
                    }
                    this.legendProcessingBacklog[url].push({ 'element': mapElementName + 'Legend', 'elementLayer': elementLayer, 'extraLayerIds': extraLayerIds });

                    if (!this.legendRequested[url]) {
                        
                        this.legendRequested[url] = true;

                        let legendRequest = $.ajax({
                            type: "GET",
                            url: url,
                            data: params,
                            dataType: "jsonp"
                        });
                        legendRequest.done((data) => {
                            this.legendResults[url] = data.layers;
                            for (let backlogID = 0; backlogID < this.legendProcessingBacklog[url].length; backlogID++) {
                                this.generateESRILegend(this.legendResults[url], this.legendProcessingBacklog[url][backlogID].element, 
                                    this.legendProcessingBacklog[url][backlogID].elementLayer,
                                    this.legendProcessingBacklog[url][backlogID].extraLayerIds);
                            }

                        });
                    }
                }

                break;
            }
            default:
                $('#warning').html('<strong>Warning:</strong> The layer of type ' + elementLayer.service + ' is not currently supported, please correct this.');
                $('#warning').show();
        }
    }

    generateWMSLegend(response, mapLegendElementName, layer) {
        let div = this.legendEle;
        div.className = 'row container-map-element-legend';

        let parser = new WMSCapabilities();
        let result = parser.read(response);
        this.checkLayers(result.Capability.Layer.Layer, layer, div);
    }

    checkLayers(layers, layer, div) {
        for (let layerID = 0; layerID < layers.length; layerID++) {
            if (layers[layerID].Name == layer.layerId) {
                for (let styleID = 0; styleID < layers[layerID].Style.length; styleID++) {
                    let legendItem = document.createElement('div');
                    legendItem.className = 'span3 ol_legend';
                    legendItem.style = 'padding: 0 15px';
                    if (layers[layerID].Style[styleID].Name == layer.style) {
                        let legendImageURL = layers[layerID].Style[styleID].LegendURL[0].OnlineResource;
                        let legendTitleName = layers[layerID].Title;
                        //check to see if the image is the same width as heigh which means it is only the legend patch.
                        if (layers[layerID].Style[styleID].LegendURL[0].size[0] == layers[layerID].Style[styleID].LegendURL[0].size[1]) {
                            // Title goes beside the patch image
                            let legendTitle = document.createElement('span')
                            legendTitle.innerHTML = legendTitleName;

                            let legendImg = document.createElement('img');
                            legendImg.src = legendImageURL;

                            legendItem.appendChild(legendImg);
                            legendItem.appendChild(legendTitle);
                            div.appendChild(legendItem)

                        } else {
                            //Title goes ontop of the patch image
                            // Title goes beside the patch image
                            let legendTitle = document.createElement('span')
                            legendTitle.innerHTML = legendTitleName;

                            let legendImg = document.createElement('img');
                            legendImg.src = legendImageURL;

                            legendItem.appendChild(legendTitle);
                            legendItem.appendChild(document.createElement('br'));
                            legendItem.appendChild(legendImg);
                            div.appendChild(legendItem)
                        }
                        break;
                    }
                }
            }
            if (layers[layerID].Layer) {
                this.checkLayers(layers[layerID].Layer, layer, div);
            }
        }
    }

    generateESRILegend(legendDefinition, mapLegendElementName, elementLayer, extraLayerIds) {
        let div = this.legendEle;
        let displayLayerIds = [];
        if (elementLayer.hasOwnProperty('layerId')) {
            displayLayerIds.push(...elementLayer.layerId.toString().split(","));
        } else if (elementLayer.geometryIntersectQueryLayers) {
            for (const l of elementLayer.geometryIntersectQueryLayers) {
                displayLayerIds.push(l.layerId.toString());
            }
        }
        if (extraLayerIds) {
            displayLayerIds.push(...extraLayerIds);
        }
        let reverseOrder = false;
        if (elementLayer.legendReverseOrder) {
            reverseOrder = true;
        }
        if (elementLayer.legendLayerId) {
            displayLayerIds = elementLayer.legendLayerId.toString().split(",");
        }
        div.className = 'row container-map-element-legend';
        if (legendDefinition) {
            switch (elementLayer.service) {
                case "MapService": {

                    for (let i = 0; i < legendDefinition.length; i++) {
                        let layer = legendDefinition[i];

                        //only display the legend elements for the layers that are shown on the map.
                        if (displayLayerIds.indexOf(layer.layerId.toString()) >= 0) {

                            for (let x = 0; x < layer.legend.length; x++) {
                                let legend = layer.legend[x];
                                let labelText = legend.label.trim();
                                if (legend.values 
                                    && legend.values.length > 0 
                                    && legend.values[0].length > 0
                                    && legend.values[0] !== labelText
                                    && legend.values[0].indexOf(labelText) > -1) {
                                    labelText = legend.values[0]
                                        .trim()
                                        .replace(/,(?=[^\s])/g, ", "); // add space after commas
                                }
                                if (labelText.length == 0) {
                                    labelText = layer.layerName.trim();
                                }

                                if (labelText.length > 0 && legend.imageData) {
                                    let legendItem = document.createElement('div');
                                    legendItem.className = 'span3 ol_legend';
                                    legendItem.style = 'padding: 0 15px';
                                    legendItem.innerHTML = '<img src="data:' + legend.contentType + ';base64,' + legend.imageData + '">' +
                                        '<span>' + labelText + '</span>';
                                    if ($.inArray(labelText, this.mapLegendLabels[mapLegendElementName]) == -1) {
                                        this.mapLegendLabels[mapLegendElementName].push(labelText);
                                        if (reverseOrder) {
                                            div.prepend(legendItem);
                                        } else {
                                            div.appendChild(legendItem);
                                        }
                                    }
                                }

                            }
                        }
                    }
                    break;
                }
                case "FeatureService": {
                    new olarcgis.styleConverter();
                    let renderer = legendDefinition.drawingInfo.renderer;
                    switch (renderer.type) {
                        case "uniqueValue": {
                            for (let styleIndex = 0; styleIndex < renderer.uniqueValueInfos.length; styleIndex++) {
                                let uniqueValueInfo = renderer.uniqueValueInfos[styleIndex];
                                
                                let legendItem = document.createElement('div');
                                legendItem.className = 'span3 ol_legend';
                                legendItem.style = 'padding: 0 15px';

                                switch (uniqueValueInfo.symbol.type) {
                                    case "esriPMS": {
                                        let labelText = legendDefinition.name.trim() + ' - ' + uniqueValueInfo.label.trim();

                                        if (labelText.length > 0 && uniqueValueInfo.symbol.imageData) {
                                            legendItem.innerHTML = '<img src="data:' + uniqueValueInfo.symbol.contentType + ';base64,' + uniqueValueInfo.symbol.imageData + '">' +
                                                '<span>' + labelText + '</span>'

                                            if ($.inArray(labelText, this.mapLegendLabels[mapLegendElementName]) == -1) {
                                                this.mapLegendLabels[mapLegendElementName].push(labelText)
                                                //labels.push(labelText)
                                                div.appendChild(legendItem)
                                            }
                                        }
                                        break;
                                    }
                                    case "esriSFS": {
                                        //Fill
                                        let labelText = legendDefinition.name.trim() + ' - ' + uniqueValueInfo.label.trim();
                                        legendItem.innerHTML = '<svg height="10" width="10"><rect width="300" height="100" style="stroke:rgb(' + uniqueValueInfo.symbol.color.slice(0, 3).join() + ');stroke-width:' + uniqueValueInfo.symbol.width + '" /></svg>' +
                                            '<span>' + labelText + '</span>'

                                        if ($.inArray(labelText, this.mapLegendLabels[mapLegendElementName]) == -1) {
                                            this.mapLegendLabels[mapLegendElementName].push(labelText)
                                            div.appendChild(legendItem)
                                        }
                                        break;
                                    }
                                    case "esriSLS": {
                                        //line
                                        let labelText = legendDefinition.name.trim() + ' - ' + uniqueValueInfo.label.trim();
                                        legendItem.innerHTML = '<svg height="10" width="10"><line x1="0" y1="0" x2="200" y2="200" style="stroke:rgb(' + uniqueValueInfo.symbol.color.slice(0, 3).join() + ');stroke-width:' + uniqueValueInfo.symbol.width + '" /></svg>' +
                                            '<span>' + labelText + '</span>'

                                        if ($.inArray(labelText, this.mapLegendLabels[mapLegendElementName]) == -1) {
                                            this.mapLegendLabels[mapLegendElementName].push(labelText)
                                            div.appendChild(legendItem)
                                        }
                                        break;
                                    }
                                    case "esriSMS": {
                                        //point
                                        let labelText = legendDefinition.name.trim() + ' - ' + uniqueValueInfo.label.trim();
                                        legendItem.innerHTML = '<svg height="10" width="10"><circle cx="50" cy="50" r="40" style="stroke:rgb(' + uniqueValueInfo.symbol.color.slice(0, 3).join() + ');stroke-width:' + uniqueValueInfo.symbol.width + '" /></svg>' +
                                            '<span>' + labelText + '</span>';
                                        if ($.inArray(labelText, this.mapLegendLabels[mapLegendElementName]) == -1) {
                                            this.mapLegendLabels[mapLegendElementName].push(labelText)
                                            div.appendChild(legendItem)
                                        }
                                        break;
                                    }
                                }
                            }
                            break;
                        }
                    }

                    for (let i = 0; i < legendDefinition.length; i++) {
                        let layer = legendDefinition[i];

                        //only display the legend elements for the layers that are shown on the map.
                        if (displayLayerIds.indexOf(layer.layerId.toString()) >= 0) {

                            for (let x = 0; x < layer.legend.length; x++) {
                                let legend = layer.legend[x];
                                let labelText = legend.label.trim();
                                if (labelText.length == 0) {
                                    labelText = layer.layerName.trim();
                                }

                                if (labelText.length > 0 && legend.imageData) {
                                    let legendItem = document.createElement('div');
                                    legendItem.className = 'span3 ol_legend';
                                    legendItem.style = 'padding: 0 15px';
                                    legendItem.innerHTML = '<img src="data:' + legend.contentType + ';base64,' + legend.imageData + '">' +
                                        '<span>' + labelText + '</span>';
                                    if ($.inArray(labelText, this.mapLegendLabels[mapLegendElementName]) == -1) {
                                        this.mapLegendLabels[mapLegendElementName].push(labelText);
                                        div.appendChild(legendItem);
                                    }
                                }

                            }
                        }


                    }
                    break;
                }
            }
        }
    }

    HEXtoRGBaArray(hexColor, alpha) {
        let r = parseInt((this.cutHex(hexColor)).substring(0, 2), 16);
        let g = parseInt((this.cutHex(hexColor)).substring(2, 4), 16);
        let b = parseInt((this.cutHex(hexColor)).substring(4, 6), 16);
        return [r, g, b, alpha]
    }

    cutHex(h) {
        return h.charAt(0) == "#" ? h.substring(1, 7) : h
    }

    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}
customElements.define('report-map', ReportMap);

/**
 * Renders a progress bar.
 * @param {Element} el The target element.
 * @constructor
 */
class Progress {
    constructor(id) {
        this.el = document.createElement('div');
        this.el.id = id;
        this.el.style.position = 'relative';
        this.el.classList = 'progress';
        this.reset();
    }

    reset() {
        this.loading = [];
        this.loaded = [];
        this.errors = [];
        this.update();
    }

    /**
     * Increment the count of loading tiles.
     */
    addLoading(url) {
        if (this.loading.length === 0) {
            this.show();
            this.el.innerHTML = "<small style='padding-left:20px'>Getting more info...please wait.</small>";
        }
        this.loading.push(url);
        if (this.errors.map(e => e.url).indexOf(url) > -1) {
            this.errors.splice(this.errors.map(e => e.url).indexOf(url), 1);
        }
        if (this.loaded.indexOf(url) > -1) {
            this.loaded.splice(this.loaded.indexOf(url), 1);
        }
        this.update();
    }

    addError(url, layerName) {
        this.errors.push({url: url, layerName: layerName});
        if (this.loading.indexOf(url) > -1) {
            this.loading.splice(this.loading.indexOf(url), 1);
        }
        this.update();
    }

    /**
     * Increment the count of loaded tiles.
     */
    addLoaded(url) {
        let this_ = this;
        setTimeout(function () {
            this_.loaded.push(url);
            if (this_.loading.indexOf(url) > -1) {
                this_.loading.splice(this_.loading.indexOf(url), 1);
            }
            if (this_.errors.length == 0) {
                this_.hide();
            }
            this_.update();
        }, 100);
    }

    /**
     * Update the progress bar.
     */
    update() {
        let width = (this.loaded / this.loading * 100).toFixed(1) + '%';
        if (width == 'Infinity%') { width = '100%'; }
        this.el.style.width = width;
        if (this.errors.length == 0) {
            if (this.loading.length == 0) {
                let this_ = this;
                this.displayFinishTimeout = setTimeout(function () {
                    this_.show();
                    this_.el.innerHTML = "<small style='padding-left:20px'>Finished, thank you</small>";
                }, 500);
                this.displayHideTimeout = setTimeout(function () {
                    this_.hide();
                }, 2000);
            }
        } else { // error
            const erroredLayerNames = this.errors.map(e => e.layerName);
            const uniqueErroredLayerNames = [...new Set(erroredLayerNames)];
            let errorMsg = 'Some map information could not be displayed.';
            if (uniqueErroredLayerNames.length == 1) {
                errorMsg = 'Some map information on the ' + uniqueErroredLayerNames[0] + ' layer could not be displayed.';
            }
            clearTimeout(this.displayFinishTimeout);
            clearTimeout(this.displayHideTimeout);
            this.show();
            this.el.style.height = '100%';
            this.el.style.background = "rgba(255, 36, 0, 0.8)";
            this.el.innerHTML = "<span><strong style='margin-left:20px'>" + errorMsg + "</strong></span><p style='margin-left:20px'>Please move the map to try again, you may be outside the Sunshine Coast Region.</p>";
        }
    }

    /**
     * Show the progress bar.
     */
    show() {
        this.el.style.visibility = 'visible';
        this.el.style.height = '20px';
        this.el.style.width = '100%';
        this.el.style.background = "rgba(0, 60, 136, 0.4)";
    }

    /**
     * Hide the progress bar.
     */
    hide() {
        this.el.style.visibility = 'hidden';
        this.el.style.width = 0;
        this.el.style.height = 0;
        this.el.innerText = "";
    }
}